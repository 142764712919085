module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-plausible@0.0.7_gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.0__react-dom@18.3_g5ldvs4vhaqgxea7m4atnhscly/node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"datawisp.io"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__m6nq4bfxoiiduzs2wf2wit5fu4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
